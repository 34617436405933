export function addModification() {
    const productCardId = document.querySelector('.__product_card__id');
    if (productCardId) {

        const titles = document.querySelectorAll('.__add_mod_title');
        titles.forEach(title=>{
            title.addEventListener('click',()=>{
                const mod = title.getAttribute('data-mod')
                if(title.classList.contains('active')) {
                    const notActiveBtns = document.querySelectorAll(`.__add_mod_btn[data-mod="${mod}"]`);
                    title.classList.remove('active');
                    title.setAttribute('data-value', '');
                    notActiveBtns.forEach(btn=>{
                        btn.checked = false;
                        btn.parentNode.querySelector('span').innerHTML = 'Выбрать'
                    });
                } else {
                    const scrollToElem = document.querySelector(`[data-scroll-to="${title.getAttribute('data-mod')}"]`);
                    const targetPosition = scrollToElem.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({ top: targetPosition, behavior: "smooth" });
                }
            });
        });
    };
}
