export function imageModal(section) {
    const openModalBtns = section ? section.querySelectorAll('.open-modal[data-type-modal="image-modal"]') : document.querySelectorAll('.open-modal[data-type-modal="image-modal"]');
    const modal = document.querySelector('.modal[data-type="image-modal"]');
    if(openModalBtns.length === 0 && !modal) return;
    const modalImage = modal.querySelector('img');
    const modalVideo = modal.querySelector('video');
    openModalBtns.forEach(btn=>{
        const image = btn.querySelector('img');
        const video = btn.querySelector('video');
        btn.addEventListener('click',()=>{
            if(image) modalImage.src = image.src;
            if(video) {
                modalVideo.src = video.src;
                modal.classList.add('video');
            }
        });
    });
}