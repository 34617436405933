import { updBasketValue } from "../../addInBasket.js";
import { addProductInBasket } from "../../cookies.js";

export function calcBundleCard() {
    const card = document.querySelector('.calc-bundle-card');
    if(card) {
        const counter = card.querySelector('.button-counter');
        counter.querySelectorAll('.icon-button').forEach(btn=>{
            btn.addEventListener('click',()=>{
                setTimeout(() => {
                    calculateTotalPrice();
                }, 100);
            });
        });
        const addInBasket = card.querySelector('.__add_in_basket__bundle-card');
        addInBasket.addEventListener('click',()=>{
            const allTitles = card.querySelectorAll('.__add_mod_title');
            let packing = '';
            let quantity = Number(counter.querySelector('span').innerText);
            let candySet = '';
            let inserts = [];
            let branding = '';
            allTitles.forEach(title=>{
                const mod = title.getAttribute('data-mod');
                const value = title.getAttribute('data-value');
                
                if(mod == 'packing' && !value || mod == 'packing' && value == '') {
                    const card = getBundleCardByBtn('package-modal');
                    card.classList.add('error');
                } else if(mod == 'packing' && value) {
                    const card = getBundleCardByBtn('package-modal');
                    card.classList.remove('error');
                }
                if(mod == 'candySet' && !value || mod == 'candySet' && value == '') {
                    const card = getBundleCardByBtn('consist-modal');
                    card.classList.add('error');
                } else if(mod == 'candySet' && value) {
                    const card = getBundleCardByBtn('consist-modal');
                    card.classList.remove('error');
                }

                if(mod == 'packing' && title.hasAttribute('data-value')) packing = Number(value);
                if(mod == 'candySet' && title.hasAttribute('data-value')) candySet = Number(value);
                if(mod == 'inserts' && title.hasAttribute('data-value')) inserts.push(parseInt(value));
                if(mod == 'branding' && title.hasAttribute('data-value')) branding = Number(value);
            });

            const bundleCards = document.querySelectorAll('.bundle-card');
            let validated = true;
            bundleCards.forEach(card=>{
                if(card.classList.contains('error')) validated = false;
            });
            if(validated) {
                addProductInBasket(
                    packing,
                    quantity,
                    candySet,
                    inserts,
                    branding
                );

                updBasketValue()
                window.location.search = '';
                setTimeout(() => {
                    window.location.reload();
                }, 150);
            };
        });
    };
};


function getBundleCardByBtn(btnName) {
    const bundleCards = document.querySelectorAll('.bundle-card');
    let findedCard = '';
    bundleCards.forEach(card=>{
        const packageModalBtn = card.querySelector(`.open-modal[data-type-modal="${btnName}"]`);
        if(packageModalBtn) {
            findedCard = card;
        };
    });
    return findedCard;
};

export function calculateTotalPrice() {
    const totalPriceModals = document.querySelector('.__total_price_modals');
    if(totalPriceModals) {
        const counter = totalPriceModals.parentNode.querySelector('.button-counter');
        const multiplication = counter.querySelector('span').innerText;
        const allTitles = document.querySelectorAll('.__add_mod_title');
        let totalPrice = 0;
        allTitles.forEach(title=>{
            let price = 0;
            if(title.getAttribute('data-price') !== '') price = title.getAttribute('data-price');
            totalPrice = totalPrice + parseInt(price);
        });

        totalPrice = totalPrice * parseInt(multiplication);

        totalPriceModals.innerHTML = totalPrice + ' ₽';
    }
};