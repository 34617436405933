import { addProductInBasket } from "../../cookies.js";
import { getCurrentQuery } from "../../utils.js";

export function orderNav() {
    const orderNav = document.querySelector('.order-navigation');
    if(orderNav) {
        const addModBtns = document.querySelectorAll('.__add_mod_btn');
        const buttonCounter = orderNav.querySelector('.button-counter');

        const addModTitles = orderNav.querySelectorAll('.__add_mod_title');
        let packing = '';
        let inserts = [];
        let branding = '';
        addModBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{

                setTimeout(() => {
                    caculateTotalPrice(addModBtns, buttonCounter);

                    addModTitles.forEach(title=>{
                        const dataMod = title.getAttribute('data-mod');
                        const value = title.getAttribute('data-value');
                        if(dataMod == 'packing') packing = Number(value);
                        if(dataMod == 'inserts') inserts.push(parseInt(value));
                        if(dataMod == 'branding') branding = Number(value);
                    });
                }, 50);
                
            });
        });

        const packingFirst = document.querySelector('.__add_mod_btn[data-mod="packing"]');
        setTimeout(() => {
            packingFirst.click();
        }, 150);
        
        const counterBtns = buttonCounter.querySelectorAll('.icon-button');
        counterBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{

                setTimeout(() => {
                    caculateTotalPrice(addModBtns, buttonCounter);

                    addModTitles.forEach(title=>{
                        const dataMod = title.getAttribute('data-mod');
                        const value = title.getAttribute('data-value');
                        if(dataMod == 'packing') packing = Number(value);
                        if(dataMod == 'inserts') inserts.push(parseInt(value));
                        if(dataMod == 'branding') branding = Number(value);
                    });
                }, 50);

            });
        });
    };
}
function getPriceOfSet() {
    const activeQueries = getCurrentQuery();
    const activeItem = document.querySelector(`.gift-compositions-section__menu__item[data-id="${activeQueries.set_id[0]}"]`);
    const activeItemPrice = activeItem.querySelector('.__item__price').innerText;
    return parseInt(activeItemPrice);
}
function caculateTotalPrice(btns, counter) {
    const totalPriceBlock = document.querySelector('.__gift__full_price');
    let totalPrice = getPriceOfSet();
    btns.forEach(activeBtn=>{
        if(activeBtn.checked) {
            const price = activeBtn.parentNode.parentNode.querySelector('span').innerText;
            totalPrice = totalPrice + parseInt(price);
        };
    });
    setTimeout(() => {
        totalPrice = totalPrice * parseInt(counter.querySelector('span').innerText);
        totalPriceBlock.innerText = totalPrice + ' ₽';
    }, 50);
}