export function modals() {
    const allModals = document.querySelectorAll(".modal");

    document.querySelectorAll('.open-modal').forEach(button => {
        button.addEventListener('click', () => {
            const modalValue = button.dataset.typeModal;
            const modalElement = document.querySelector(`[data-type="${modalValue}"]`);
            modalElement.dataset.modalState = "open";
            document.documentElement.style.overflow = "hidden";
        });
    });

    document.querySelectorAll('.btn-modal-close, .modal__inset').forEach(closeButton => {
        if(!closeButton.classList.contains('__image_modal_close')) {
            closeButton.addEventListener('click', closeModal);
        } else {
            closeButton.addEventListener('click',()=>{
                let otherIsActive = false;
                allModals.forEach(el=>{
                    if(el.dataset.type == "image-modal") {
                        el.dataset.modalState = 'close';
                        el.classList.remove('video');
                    } else if(el.dataset.type !== "image-modal" && el.dataset.modalState == "open") {
                        otherIsActive = true;
                    };
                });
                if(!otherIsActive) {
                    document.documentElement.style.overflow = null;
                };
            });
        }
    });

    function closeModal() {
        allModals.forEach((el) => {
            el.dataset.modalState = "close";
        });
        document.documentElement.style.overflow = null;
    }
}


// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { modals } from "./путь/к/файлу/modals.js";

// Активация: modals();