import { z } from "../libraries/zod.js"
import { getBasketFromCookie, getGiftItemsCookie, setBasketToCookie } from "./cookies.js";
import { findParentWithClass, sendData, sendFormData } from "./utils.js";


function getTextInsideSquareBrackets(text) {
    const startIndex = text.indexOf('['); // Находим индекс первой открывающей скобки
    const endIndex = text.lastIndexOf(']'); // Находим индекс последней закрывающей скобки
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) { // Проверяем, что скобки найдены и первая скобка находится перед последней
        return text.substring(startIndex + 1, endIndex); // Возвращаем текст между первой и последней скобками
    } else {
        return ''; // Если скобки не найдены или первая скобка находится после последней, возвращаем пустую строку
    }
}

const schemaCallBack = z.object({
    email: z.string(),
    phone: z.string()
        .min(18),
    first_name: z.string()
        .min(1),
    address: z.string()
        .min(1),
    contact_person: z.string(),
    date_to_deliver: z.string(),
    payment_method: z.string(),
    payment_method: z.string()
        .min(1),
});

const schemaLeadForm = z.object({
    email: z.string(),
    phone: z.string()
        .min(18),
    first_name: z.string()
        .min(1),
});

export function validation() {
    const formaliztionForm = document.getElementById('formaliztion-form');
    if(formaliztionForm) {
        formValidate(
            formaliztionForm, 
            schemaCallBack, 
            (body) => {
                const formData = new FormData(formaliztionForm);
                
                const paymentMethodElem = formaliztionForm.querySelector('#payment-method');
                const paymentSelected = paymentMethodElem.dataset.selected;
                if(paymentSelected !== '') {
                    formData.delete('payment_method')
                    formData.append('payment_method', paymentSelected);

                    const file = formaliztionForm.querySelector('#file-input');
                    if(file.files[0] && paymentSelected == 'bank_transfer') {
                        formData.append('document', file.files[0]);
                    }
                }

                const giftItemsCookie = getGiftItemsCookie()
                const giftItems = JSON.stringify(giftItemsCookie);
                formData.append('order_gifts', giftItems);

                sendFormData(formData,'/api/feedback_request/').then(res=>{
                    const basket = getBasketFromCookie();
                    basket.forEach(basketElem=>{
                        delete basketElem.id;
                        giftItemsCookie.forEach(orderElem=>{
                            const match = objectsEqual(basketElem, orderElem);
                            if(match) {
                                const index = basket.indexOf(basketElem);
                                basket.splice(index, 1);
                                setBasketToCookie(basket);
                            };
                        });
                    });

                    window.location.reload()
                }).catch(error=>{
                    const fields = document.querySelectorAll('.field');
                    fields.forEach(field=> {
                        field.classList.add('field_error');
                    });
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        );
    }
    const leadForm = document.getElementById('lead-form');
    if(leadForm) {
        formValidate(
            leadForm,
            schemaLeadForm,
            (body) => {
                sendData(body,'/api/feedback_request/').then(res=>{
                    window.location.reload()
                }).catch(error=>{
                    const fields = document.querySelectorAll('.field');
                    fields.forEach(field=> {
                        field.classList.add('field_error');
                    });
                    console.log("error on leadForm");
                    console.error(error);
                })
            }
        )
    }
}

function formValidate(form, schema, callBack, formData) {
    form.onsubmit = (e) => {
        e.preventDefault();
    }
    const button = form.elements.button;

    const inputs = Object.keys(getBody(form))
        .map(el=> 
            document.querySelectorAll(`*[name=${el}]`)
        )
        .map(el=>Array.from(el).find(elem=>elem.closest(`#${form.id}`)));

    inputs.forEach((el)=>{
        el.oninput = (evt) => {
            inputs.forEach((e) => {
                if(e.parentNode.classList.contains('field')) {
                    e.parentNode.classList.remove('field_error');
                } else {
                    const field = findParentWithClass(e, 'field')
                    field.classList.remove('field_error')
                }
            });

            const body = getBody(evt.target.form);
            validateParse({
                schema: schema,
                body,
            }).then(res => {
                if(button.classList.contains('button_primary')) button.classList.remove('button_primary_disabled');
                if(button.classList.contains('button_outline')) button.classList.remove('button_outline_disabled');
                button.onclick = () => {
                    const formDataBody = new FormData(form);
                    if(callBack) formData ? callBack(formDataBody) : callBack(body);
                }
            }).catch(error => {
                const parse = JSON.parse(`[${getTextInsideSquareBrackets(error.toString())}]`);
                const nameErrorInput = parse.map(el=>el.path[0]);
                const input = inputs.filter(el=>nameErrorInput.includes(el.name));
                if(button.classList.contains('button_primary')) button.classList.add('button_primary_disabled');
                if(button.classList.contains('button_outline')) button.classList.add('button_outline_disabled');
                input.forEach((el) => {
                    if(el.parentNode.classList.contains('field')) {
                        el.parentNode.classList.add('field_error');
                    } else {
                        const field = findParentWithClass(el, 'field')
                        field.classList.add('field_error')
                    };
                });
                button.onclick = () => {};
            });
        }
    })
}


async function validateParse(validateInfo) {
    try {
        validateInfo.schema.parse(validateInfo.body);
        console.log("Validation successful");
        if(typeof validateInfo?.callback == 'function')validateInfo?.callback();
        return true;
    } 
    catch (error) {
        if (error instanceof z.ZodError) {
            // console.error("Validation failed:", error.errors);
            throw new Error(JSON.stringify(error.errors));
        } else {
            // console.error("Unknown error", error);
        }
    }
}

function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        body[name] = value;
    }
    return body;
}

function getQuery(query) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(query);
}

function objectsEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
        if (!keys2.includes(key) || !objectsEqual(obj1[key], obj2[key])) return false;
    }
    return true;
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { validation } from "./путь/к/файлу/validation.js";

// Активация: validation();