import '../styles/main.scss';

import { accordion } from "./modules/base/accordion.js";
import { inputs } from "./modules/base/inputs.js";
import { anchor } from "./modules/base/anchor.js";
import { modals } from "./modules/base/modals.js";
import { select } from "./modules/base/select.js";
import { showAll } from "./modules/base/showAll.js";
import { customRangeInput } from "./modules/base/customRangeInput.js";
import { header } from "./modules/header.js";
import { cookie } from "./modules/cookies.js";

import * as pages from './modules/pages/index.js';

import { slider } from "./modules/slider.js";
import { filters } from "./modules/filters.js";
import { validation } from "./modules/validation.js";
import { pagination } from "./modules/pagination.js";

import { packageModal } from "./modules/modals/packageModal.js";
import { consistModal } from "./modules/modals/consistModal.js";
import { attachmentsModal } from "./modules/modals/attachmentsModal.js";
import { brandingModal } from "./modules/modals/brandingModal.js";
import { formalizationModal } from "./modules/modals/formalizationModal.js";
import { imageModal } from './modules/modals/imageModal.js';

import { productCard } from "./modules/product-card.js";
import { addModification } from "./modules/addModification.js";
import { calendar } from "./modules/calendar.js";
import { orderNav } from "./modules/orderNav.js";
import { buttonCounter } from "./modules/buttonCounter.js";
import { addInBasket } from "./modules/addInBasket.js";
import { copyDetails } from './modules/copyDetails.js';

import { mouseToMove } from "./modules/animations/moveToMouse.js";
import { popUp } from "./modules/animations/popUp.js";
import { customCursorSlider } from "./modules/animations/customCursorSlider.js";
import { santaAnimation } from "./modules/animations/santaAnimation.js";
import { candyFabricAutoSlider } from "./modules/animations/candyFabricAutoSlider.js";



accordion()
inputs()
anchor()
modals()
select()
showAll()
customRangeInput()
header()
cookie()

slider()
filters()
validation()
pagination()

packageModal()
consistModal()
attachmentsModal()
brandingModal()
formalizationModal()
imageModal()

productCard()
addModification()
calendar()
orderNav()
buttonCounter()
addInBasket()
copyDetails()

mouseToMove()
popUp()
// customCursorSlider()
santaAnimation()
candyFabricAutoSlider()
