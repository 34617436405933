import { addProductInBasket, getBasketFromCookie } from "./cookies.js";

export function addInBasket() {
    const basketBtns = document.querySelectorAll('.__add_in_basket__btn');
    document.addEventListener('DOMContentLoaded',()=>{
        updBasketValue() 
    });
    if(basketBtns.length > 0) {
        basketBtns.forEach(btn=>{
            const btnId = btn.getAttribute('data-id');
            btn.addEventListener('click',()=>{
                const productCardId = document.querySelector('.__product_card__id');
                if(productCardId) {
                    const buttonCounter = btn.parentNode.querySelector('.button-counter');
                    const candySetBtn = document.querySelector('.__candy_set__btn:checked');

                    const titles = document.querySelectorAll(`.__add_mod_title[data-mod]`);
                    let inserts = [];
                    let branding = '';
                    titles.forEach(title=>{
                        const value = title.getAttribute('data-value')
                        if(title.getAttribute('data-mod') == 'inserts' && value) inserts.push(parseInt(value));
                        if(title.getAttribute('data-mod') == 'branding' && value) branding = value;
                    });
                    
                    const quantity = buttonCounter.querySelector('span').innerText;
                    if(quantity == '0') quantity = 1;
                    
                    addProductInBasket(
                        Number(btnId), 
                        Number(quantity), 
                        Number(candySetBtn.getAttribute('data-id')),
                        inserts,
                        Number(branding),
                    );
                } else {
                    const card = btn.closest('.product-card');
                    const candySetBtn = card.querySelector('.__candy_set__btn:checked');
                    if(candySetBtn) {
                        addProductInBasket(Number(btnId), 1, Number(candySetBtn.getAttribute('data-id')));
                    } else {
                        addProductInBasket(Number(btnId), 1);
                    }
                }

                updBasketValue();
            });
        });
    };
};

export function updBasketValue() {
    const basket = getBasketFromCookie();
    let fullQuantity = 0;
    basket.forEach(elem=>{
        const quantity = elem.quantity;
        fullQuantity = fullQuantity + quantity;
    });

    const basketValueElems = document.querySelectorAll('.__basket_value_counter');
    if(basketValueElems.length > 0) {
        basketValueElems.forEach(elem=>{
            if(fullQuantity !== 0) {
                elem.classList.add('show');
                elem.classList.add('active');
            } else {
                elem.classList.remove('show');
            };
            
            setTimeout(() => {
                elem.innerHTML = fullQuantity;
                elem.classList.remove('active');
            }, 150);
        })
    };
}