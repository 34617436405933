import { getBasketFromCookie, setBasketToCookie } from "../../cookies.js";

export function basketModals() {
    const container = document.querySelector('.basket-section__container');
    if(container) {
        const brandingModal = document.querySelector('.modal[data-type="branding-modal"]');
        const brandingCards = brandingModal.querySelectorAll('.adder-card');

        brandingCards.forEach(card=>{
            const addModBtn = card.querySelector('.__add_mod_btn');
            addModBtn.addEventListener('click',()=>{
                const cardId = parseInt(brandingModal.dataset.openId);
                const btnId = parseInt(addModBtn.dataset.id)

                const basket = getBasketFromCookie();
                basket.forEach(elem=>{
                    if(elem.id == cardId) {
                        elem.branding = btnId;
                    };
                });
                setBasketToCookie(basket);
            });
        });


        const attachmentsModal = document.querySelector('.modal[data-type="attachments-modal"]');
        const addModBtns = attachmentsModal.querySelectorAll('.__add_mod_btn');

        addModBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                let totalId = []
                addModBtns.forEach(activeBtn=>{
                    if(activeBtn.checked) {
                        const cardId = parseInt(attachmentsModal.dataset.openId);
                        const btnId = parseInt(activeBtn.dataset.id);
                        totalId.push(btnId);
                        const basket = getBasketFromCookie();
                        basket.forEach(elem=>{
                            if(elem.id == cardId) {
                                elem.inserts = totalId;
                            };
                        });

                        setBasketToCookie(basket);
                    };
                });
            });
        });

        const modalInsets = document.querySelectorAll('.modal__inset');
        modalInsets.forEach(inset=>{
            inset.addEventListener('click',()=>{
                window.location.reload();
            });
        }); 
        const closeModalBtns = document.querySelectorAll('.btn-modal-close');
        closeModalBtns.forEach(btn=>{
            btn.addEventListener('click',()=>{
                window.location.reload();
            });
        });
    };
}